<template>
        <!-- 收款情况 -->
    <section class="receipt-situation padding_10_15">
        <div id="receiptECharts"></div>
        <div class="receipt-content flex align-center justify-around">
            <div class="receipt-item flex align-center" v-for="item in receiptOptions" :key="item.name">
                <img class="icon" :src="item.icon" alt="" @click="handleClickReceipt(item)">
                <div class="content">
                    <div class="label">{{ item.label }}</div>
                    <div class="value">￥{{ item.value }}</div>
                </div>
                <div class="triangle-up" v-show="item.name === activeTriangle"
                     :style="{ borderBottomColor: item.color }">
                </div>
            </div>
        </div>
        <div class="receipt-descriptions flex align-center justify-around"
             :style="{ backgroundColor }">

            <div class="item flex align-center" v-for="item in receiptDetailOptions" :key="item.name">
<!--                <div class="label" :style="item.label==='服务费'?'width: 40px':''">{{ item.label }}</div>-->
                <el-tooltip placement="top-start" effect="light">
                    <div slot="content">￥{{ item.value }}</div>
                    <div class="value" @click="skip(item)"
                         style="width: 75px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;cursor:pointer;">
                      <span>{{ item.label }}</span>
                        ￥{{ item.value }}
                    </div>
                </el-tooltip>
            </div>
        </div>
    </section>
</template>

<script>
    import {receiptOptions, receiptDetailOptions, receiptEChartsOptions,} from "@/views/home/data";
    import * as echarts from "echarts";
    import {getReportGeneralApi} from "@/api/home";
    import {numberFormat} from "@custom/index";

    export default {
        name: "receipt-situation",
        data() {
            return {
                receiptOptions: receiptOptions,
                receiptDetailOptions: receiptDetailOptions,
                receiptEChartsOptions: receiptEChartsOptions,
                backgroundColor: "#F85851",
                activeTriangle: "a1",
                rate: 75.61,
                detailArr: []
            };
        },
        props: {
            collectionData: {
                type: Object,
                default: () => ({})
            }
        },
        methods: {
            //切换收款类型
            handleClickReceipt({color, name}) {
                this.backgroundColor = color;
                this.activeTriangle = name;
                let detailArr = this.detailArr;
                let receiptDetailOptions = detailArr.find(o => o.name === name);
                let {value} = receiptDetailOptions;
                this.receiptDetailOptions = value;
            },
            //初始化eCharts
            eChartsInit() {
                this.receiptECharts = echarts.init(document.getElementById("receiptECharts"));
                //创建eCharts并赋值
                this.receiptECharts.setOption(receiptEChartsOptions, true);
                // 添加窗体拉伸事件
                window.addEventListener("resize", () => {
                    this.receiptECharts.resize();
                });
            },

            //获取收款情况数据
            async getReportGeneralApi(paramData) {
                //调用接口获取数据
                let res = await getReportGeneralApi(paramData);
                //处理数据
                let {info} = res;
                let {AR, accomplish, awaitD} = info;
                //eCharts数据 已收/待收
                let proportionArr = [
                    //{value: Number(AR.ARPercent), name: '应收款'},
                    {value: Number(accomplish.accomplishPercent), name: '已收款'},
                    {value: Number(awaitD.awaitPercent), name: '待收款'},
                ];

                //切换项数据 应收、已收、待收
                let sumArr = [
                    {value: AR.ARsum, name: "a1"},
                    {value: accomplish.accomplishSum, name: "a2"},
                    {value: awaitD.awaitSum, name: "a3"}
                ];

                //切换项子数据
                let detailArr = [
                    {
                        name: "a1",
                        value: [
                            {label: "定金", name: "a1", value: numberFormat(AR.ARDetails.handsel / 100),},
                            {label: "电费", name: "a2", value: numberFormat(AR.ARDetails.elect / 100),},
                            {label: "水费", name: "a3", value: numberFormat(AR.ARDetails.water / 100),},
                            {label: "租金", name: "a4", value: numberFormat(AR.ARDetails.rent / 100),},
                            {label: "押金", name: "a5", value: numberFormat(AR.ARDetails.reserve / 100),},
                            {label: "服务费", name: "a6", value: numberFormat(AR.ARDetails.serviceCharge / 100),}
                        ]
                    },
                    {
                        name: "a2",
                        value: [
                            {label: "定金", name: "a1", value: numberFormat(accomplish.accomplishDetails.handsel / 100),},
                            {label: "电费", name: "a2", value: numberFormat(accomplish.accomplishDetails.elect / 100),},
                            {label: "水费", name: "a3", value: numberFormat(accomplish.accomplishDetails.water / 100),},
                            {label: "租金", name: "a4", value: numberFormat(accomplish.accomplishDetails.rent / 100),},
                            {label: "押金", name: "a5", value: numberFormat(accomplish.accomplishDetails.reserve / 100),},
                            {label: "服务费", name: "a6", value: numberFormat(accomplish.accomplishDetails.serviceCharge / 100),}
                        ]
                    },
                    {
                        name: "a3",
                        value: [
                            {label: "定金", name: "a1", value: numberFormat(awaitD.awaitDetails.handsel / 100),},
                            {label: "电费", name: "a2", value: numberFormat(awaitD.awaitDetails.elect / 100),},
                            {label: "水费", name: "a3", value: numberFormat(awaitD.awaitDetails.water / 100),},
                            {label: "租金", name: "a4", value: numberFormat(awaitD.awaitDetails.rent / 100),},
                            {label: "押金", name: "a5", value: numberFormat(awaitD.awaitDetails.reserve / 100),},
                            {label: "服务费", name: "a6", value: numberFormat(awaitD.awaitDetails.serviceCharge / 100),}
                        ]
                    }
                ];

                //创建eCharts并赋值
                let receiptEChartsOptions = this.receiptEChartsOptions;
                receiptEChartsOptions.series[0].data = proportionArr;
                this.receiptECharts.setOption(receiptEChartsOptions, true);

                // 切换项数据 应收、已收、待收赋值
                let receiptOptions = this.receiptOptions;
                for (let item of receiptOptions) {
                    let v = sumArr.find(o => o.name === item.name);
                    item.value = numberFormat(v.value / 100);
                }

                //切换项子数据赋值
                let receiptDetailOptions = detailArr.find(o => o.name === this.activeTriangle);
                this.receiptDetailOptions = receiptDetailOptions.value;
                this.detailArr = detailArr;
            },

            //费用类型点击跳转账单
            skip(item) {
                const { name } = item;
                const { startDate, endDate } = this.collectionData;
                const startAndEndDate = [startDate, endDate];
                let billType = null;
                let billStatus = null;
                //根据切换项判断账单状态
                switch (this.activeTriangle) {
                    case "a1":
                        billStatus = 0;
                        break;
                    case "a2":
                        billStatus = 600602;
                        break;
                    case "a3":
                        billStatus = 600601;
                        break;
                }
                //根据点击的子数据name判断账单类型
                switch (name) {
                    case "a1":
                        billType = 500505;
                        break;
                    case "a2":
                        billType = 500501;
                        break;
                    case "a3":
                        billType = 500502;
                        break;
                    case "a4":
                        billType = 500504;
                        break;
                    case "a5":
                        billType = 500512;
                        break;
                    case "a6":
                        billType = 500507;
                        break;
                }
                //跳转账单管理界面，并传递搜索条件
                this.$router.push({name: "payment-record", params: {billType, billStatus, startAndEndDate}});
            }
        },
      async  mounted() {
        await    this.eChartsInit();
            let value = this.collectionData;
            const { startDate = null, endDate = null } = value;
            if(startDate && endDate) {
                this.getReportGeneralApi(value);
            }
        },
        beforeDestroy() {
            // 移除窗体拉伸事件
            window.removeEventListener("resize", () => {
                this.receiptECharts.resize();
            });
        },
        watch: {
            collectionData(value) {
                this.getReportGeneralApi(value);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .receipt-situation {

        #receiptECharts {
            width: 100%;
            height: VH(370px);
        }

        .receipt-content {
            margin: VH(20px) 0 VH(30px);


            .receipt-item {
                position: relative;

                .icon {
                    width: VW(44px);
                    cursor: pointer;
                }

                .content {
                    color: #333;
                    font-weight: 400;
                    padding-left: VW(15px);

                    .label {
                        font-size: rem(13px);
                        padding-bottom: VH(5px);
                    }

                    .value {
                        font-size: rem(15px);
                    }
                }

                .triangle-up {
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 20px solid;
                    position: absolute;
                    bottom: VH(-30px);
                    left: VW(20px);
                }
            }
        }

        .receipt-descriptions {
            height: VH(46px);
            line-height: VH(46px);
            font-size: rem(11px);
            color: #333;
            border-radius: 5px;
            padding-left: VW(15px);

            .item {
                width: 25%;
                /*padding: 0 VW(5px);*/

                .value {
                    padding-left: VW(5px);
                }
            }
        }
    }
</style>
