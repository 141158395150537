<template>
    <section class="complaint padding_10_15">
        <div class="top-bar bg-white" style="flex-wrap: wrap;">
            <!-- 2024-04-15 张晓瑜修改投诉搜索 -->
            <div style="display:flex; padding:10px 20px 0px 20px">
                <div style="margin: 0px 20px 10px ">
                <span class="form_selest">投诉编号</span>
                <el-input v-model="formSearch.feedSn"  size="small"
                      placeholder="请输入投诉编号" style="margin: 0 9px; width: 200px"/>
                 </div>
                <div style="margin: 0px 20px 10px 0px">
                    <span class="form_selest">投诉类型</span>
                    <el-select v-model="formSearch.feedType" placeholder="全部" size="small" style="margin: 0 10px">
                        <el-option label="全部" value="0"/>
                        <el-option label="功能使用" value="700301"/>
                        <el-option label="优化建议" value="700302"/>
                        <el-option label="新增功能" value="700303"/>
                        <el-option label="其他问题" value="700304"/>
                    </el-select>
                </div>
                <div style="margin: 0px 20px 10px 0px">
                    <span class="form_selest">投诉人</span>
                    <el-input v-model="formSearch.leasor"  size="small"
                        placeholder="请输入投诉人" style="margin: 0 9px; width: 200px"/>
                </div>
                <div style="margin: 0px 20px 10px 0px">
                    <span class="form_selest">联系方式</span>
                    <el-input v-model="formSearch.phone"  size="small"
                        placeholder="请输入联系方式" style="margin: 0 9px; width: 200px"/>
                </div>
                <div style="margin: 0px 20px 10px 0px">
                    <span class="form_selest">租房地址</span>
                    <el-input v-model="formSearch.address"  size="small"
                        placeholder="请输入租房地址" style="margin: 0 9px; width: 200px"/>
                </div>
            </div>

            <div style="display:flex; padding: 10px 20px 10px 20px">
                <div style="margin: 0px 20px 10px 20px">
                    <span class="form_selest">跟进状态</span>
                    <el-select v-model="formSearch.state" placeholder="全部" size="small" style="margin: 0 10px">
                        <el-option label="全部" :value="0"/>
                        <el-option label="未处理" :value="600401"/>
                        <el-option label="已处理" :value="600402"/>
                    </el-select>
                </div>
                <div  style="margin: 0px 20px 10px 0px">
                    <span class="form_selest">投诉时间</span>
                    <el-date-picker v-model="feedDate" type="daterange"  size="small" value-format="yyyy-MM-dd" style="margin: 0 10px"
                                            format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                                            end-placeholder="结束日期"/>
                </div>
                <div style="margin: 0px 20px 10px 0px">
                    <el-button class="chaxun_btn" type="primary" size="small" @click="handleSearch">查询</el-button>
                </div>
                <div>
                    <el-button @click="handleReset" size="small">重置</el-button>
                </div>
            </div>


        </div>


        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getFeedbackTableData" :columns="tableColumn"
                   :query="formSearch" tooltip-effect="light" :height="720">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="row.stateCode === 600401" @click="handle(row)">处理</span>
                        <span class="table-btn"  @click="handlePopto(row)">照片</span>
                        <span class="table-btn"
                              v-if="row.stateCode === 600402 && getButtonMenu('complaintToExamine')"
                              @click="toExamineShowTrue(row)">
                            审核
                        </span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <r-e-dialog title="投诉处理" :visible.sync="dialogVisible" show-footer top="10vh" width="600px"
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
                <div class="flex">
                    <el-form-item label="处理内容" prop="feedContent">
                        <el-input type="textarea" :rows="2" v-model="formPublish.feedContent" style="width: 350px"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>

        <!-- 2024/04/15 张晓瑜新增照片弹窗 -->
        <r-e-dialog title="投诉照片" :visible.sync="dialogPoptoVisible" show-footer top="10vh" width="600px"
                    @click-submit="PoptoClickSubmit" @click-cancel="PoptoClickCancel" @close="PoptoClickCancel">
                        <div class="flex" style="height: 300px;overflow:scroll; flex-wrap: wrap;">
                          <!-- <div v-for="(item,index) in data.thumbnail" :key="index"> -->
                          <div class="flex justify-center" style="margin:10px; background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px" v-for="item in dialogPoptoPhotos" :key="item">
                            <el-image :src="item" class="flex align-center"
                              :preview-src-list="dialogPoptoPhotos" >
                              <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                              </div>
                              <div slot="placeholder">加载中...</div>
                           </el-image>
                          </div>
                          <!-- </div> -->
                        </div>
        </r-e-dialog>

        <r-e-dialog title="处理审核" :visible.sync="toExamineShow" show-footer top="10vh" width="600px"
                    @click-submit="toExamineSubmit" @click-cancel="toExamineCancel" @close="toExamineCancel">
            <el-form ref="formPublish1" label-width="110px" size="small" :model="formPublish" :rules="rules">
                <div class="flex">
                    <el-form-item label="处理内容" prop="feedContent">
                        <el-input type="textarea" :rows="2" v-model="formPublish.feedContent" style="width: 350px" readonly/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="审核状态" prop="authState">
                        <el-radio-group v-model="formPublish.authState">
                            <el-radio-button label="1">通过</el-radio-button>
                            <el-radio-button label="2">驳回</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <div class="flex" v-if="formPublish.authState === '2'">
                    <el-form-item label="驳回原因" prop="authFeedContent">
                        <el-input type="textarea" :rows="2" v-model="formPublish.authFeedContent" style="width: 350px"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>
    </section>
</template>

<script>
import {tableComplaintColumn} from "@/views/home/data";
import {getFeedbackList, feedbackDealFeed, authFeed} from "@/api/home";
import {MessageSuccess} from "@custom/message";
import {buttonCookies} from "@storage/cookies";
import {createFullImageUrl} from "@/components/Upload";
export default {
    name: "complaint",
    data() {
        return {
            formSearchvalue: null,
            formSearchvaluetow: null,
            aeroplane: [
                {title: "全部", id: 1,},
                {title: "投诉编号", id: "feedSn",},
                {title: "投诉人", id: "leasor",},
                {title: "联系方式", id: "phone",},
                // {title: "租房地址", id: 1444,},
            ],
            housing: [
                {title: "富航公寓", id: "1",},
                {title: "回购经适房", id: "2",},
                {title: "拆迁安置房", id: "3",},
                {title: "竞配产权房", id: "4",},
                {title: "高级人才房", id: "5",},
                {title: "五象四号点", id: "6",},
            ],
            tableColumn: tableComplaintColumn,
            tableData: [],
            formSearch: {
                feedSn: null,
                leasor: null,
                feedType: null,
                state: null,
                phone: null,
                address:null,
            },
            dialogVisible: false,
            formPublish: {
                feedContent: null,
                uuid: null,
                authFeedContent: null,
                authState: null,
            },
            rules: {
                feedContent: [{required: true, message: "请输入处理内容", trigger: "blur"},],
                authFeedContent: [{required: true, message: "请输入驳回原因", trigger: "blur"},],
                authState: [{required: true, message: "请选择审核状态", trigger: "change"},],
            },
            toExamineShow: false,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            feedDate:[], // 投诉时间
            dialogPoptoVisible:false, //照片弹窗
            photos:[],
        };
    },
    methods: {
        // 选择
        formSearchvaluechange(e) {
            this.formPublish[this.formSearchvalue] = e;
        },
        //获取投诉列表
        getFeedbackTableData(params) {
            return getFeedbackList(params);
        },
        //查询
        handleSearch() {
            this.$refs["orderTableRef"].getTableData();
        },
        //重置
        handleReset() {
            this.formSearch = {};
            this.feedDate = [];
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        //处理
        handle(data) {
            const {uuid} = data;
            this.formPublish.uuid = uuid;
            this.dialogVisible = true;
        },
        //处理确认
        clickSubmit() {
            let that = this;
            // 表单提交
            that.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    const {uuid, feedContent} = that.formPublish;
                    feedbackDealFeed({uuid, feedContent}).then((res) => {
                        MessageSuccess("投诉处理申请成功");
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        //处理取消
        clickCancel() {
            this.$refs["formPublish"].resetFields();
            this.handleSearch();
            this.dialogVisible = false;
        },
        //审核
        toExamineShowTrue(data) {
            this.formPublish = data;
            this.toExamineShow = true;
        },
        //审核确认
        toExamineSubmit() {
            let that = this;
            that.$refs["formPublish1"].validate((valid) => {
                if (valid) {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    const {uuid, authFeedContent, authState} = that.formPublish;
                    const data = {uuid, authFeedContent, authState}
                    authFeed(data).then((res) => {
                        MessageSuccess("投诉处理成功");
                        that.toExamineCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        //审核取消
        toExamineCancel() {
            this.formPublish = {feedContent: null, uuid: null, authFeedContent: null, authState: null,};
            this.$refs["formPublish1"].resetFields();
            this.handleSearch();
            this.toExamineShow = false;
        },
        //获取按钮权限数组
        getButtonMenu(menu) {
            return JSON.parse(buttonCookies.get()).map((item) => item.url).some((v) => v === menu);
        },
        // 2024/04/15 张晓瑜新增照片点击事件
        handlePopto(data) {
            let { photos } = data;

            // 将 photos 按分号分隔成数组，并过滤掉空字符串
            let photoUrls = photos ? photos.split(",").filter(item => item !== "") : [];
            let processedPhotoUrls = photoUrls.map(createFullImageUrl); // 对每个图片链接应用 createFullImageUrl 函数

            // 将 photoUrls 存储到数组中
            this.dialogPoptoVisible = true;
            this.dialogPoptoPhotos = processedPhotoUrls; // 将处理后的结果存储到 dialogPoptoPhotos 中
        },
        // 照片提交
        PoptoClickSubmit(){
            this.dialogPoptoVisible = false;
        },
        // 照片取消
        PoptoClickCancel(){
            this.handleSearch();
            this.dialogVisible = false;
            this.dialogPoptoVisible = false;
        },
    },
    watch: {
        // 投诉
        feedDate(value) {
            if (value && value.length === 2) {
                let [feedStartDate, feedEndDate] = value;
                this.formSearch.feedStartDate = feedStartDate;
                this.formSearch.feedEndDate = feedEndDate;
            } else {
                this.formSearch.feedStartDate = null;
                this.formSearch.feedEndDate = null;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.form_selest {
    margin-top: 6px;
    width: 120px;
    text-align: center;
    margin-left: -22px;
    padding-left: 12px;
    vertical-align: middle;
}

.complaint {
    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        // padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            white-space: nowrap;
            text-align: center;
        }
    }
}

.chaxun_btn {
    margin-left: 12px;
}
</style>
