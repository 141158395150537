import { timeFormat, numberFormat } from "@custom/index";
import { conversionAmount } from "@/utils/hooks";

// 首页tabs
export const homeTabOptions = [
    {
        label: "概况",
        name: "overview",
        value: 0
    },
    {
        label: "预约",
        name: "appointment",
        value: 0
    },
    {
        label: "续租",
        name: "renewalToExamine",
        value: 0
    },
    {
        label: "退房",
        name: "checkOut",
        value: 0
    },
    // 2024-3-11-陈守亮-隐藏换房
    // {
    //     label: "换房",
    //     name: "exchangeHouses",
    //     value: 0
    // },
    {
        label: "投诉",
        name: "complaint",
        value: 0
    },
    {
        label: "维修",
        name: "repair",
        value: 0
    }
];

// 首页搜索条件
export const timeButtons = [
    {
        label: "今日",
        name: "day"
    },
    {
        label: "本月",
        name: "month"
    }
];

// 概况tabs - 4个部分
export const overviewOptions = [
    {
        label: "运营情况",
        name: "operation-situation",
        className: "bg-white"
    },

    {
        label: "收款情况",
        name: "receipt-situation",
        className: "bg-white"
    },
    {
        label: "上月退租情况",
        name: "retire-situation",
    },
    {
        label: "小区动态",
        name: "community-dynamic",
        className: "bg-white"
    }
];

// 概况tabs - 运营情况 - 统计
export const operationOptions = [
    {
        src: require("@/assets/images/home/total.png"),
        name: "total",
        color: "#1221D6",
        label: "空置",
        desc: "空置房数",
        number: 0,

    },
    {
        src: require("@/assets/images/home/known.png"),
        name: "known",
        color: "#F85851",
        label: "已占有",
        desc: "已租房数",
        number: 0,

        tooltip: {
            valueFormatter: function (value) {
                if (value !== value) value = 0   //陈守亮暂时修改甲方数据
                return value
            }
        }
    }
];

// 概况tabs - 运营情况 - echarts
export const operationECharts = {
    tooltip: {
        trigger: "item",
        /*formatter: function (params) {
            return `${params.marker}${params.data.name}<span style="padding: 0 5px"></span> ${params.data.value}`;
        }*/
    },
    color: ["#FEE5E4", "#F85951"],
    series: [
        {
            type: "pie",
            radius: ["50%", "70%"],
            label: {
                show: false,
            },
            data: []
        }
    ]
};

// 概况tabs - 运营情况 - slider
export const operationSlider = [
    {
        label: "投诉量",
        name: "a1",
        value: 0,
        rate: 0
    },
    {
        label: "报修量",
        name: "a2",
        value: 0,
        rate: 0
    },
    // {
    //     label: "已维修量",
    //     name: "a3",
    //     value: 0,
    //     rate: 0
    // },
    {
        label: "新签约",
        name: "a4",
        value: 0,
        rate: 0
    },
];

// 概况tabs - 上月退租情况
export const retireOptions = [
    {
        label: "退租数量",
        name: "a1",
        value: 0,
        color: "rgb(255,79,1)",
    },
    {
        label: "退租率",
        name: "a2",
        value: 0,
        unit: "%",
        color: "rgb(251,56,56)",
    },
    {
        label: "到期退租",
        name: "a3",
        value: 0,
        color: "rgb(24,94,198)",
    },
    {
        label: "中途退租",
        name: "a4",
        value: 0,
        color: "rgb(51,51,51)",
    },
];

// 概况tabs - 收款情况
export const receiptOptions = [
    {
        label: "应收款",
        name: "a1",
        value: 0,
        color: '#F85851',
        icon: require("@/assets/images/home/need.png")
    },
    {
        label: "已收款",
        name: "a2",
        value: 0,
        color: 'aqua',
        icon: require("@/assets/images/home/already.png")
    },
    {
        label: "待收款",
        name: "a3",
        value: 0,
        color: '#5C84FB',
        icon: require("@/assets/images/home/not.png")
    },
];

// 概况tabs - 收款情况
export const receiptEChartsOptions = {
    tooltip: {
        trigger: 'item',
        formatter: function (params) {
            return `${params.marker}${params.data.name}<span style="padding: 0 5px"></span> ${params.value}%`;
        }
    },
    legend: {
        bottom: 0,
        left: 'center'
    },
    color: [
        //'#FB5951',
        '#31AB3F',
        '#FB9851'],
    series: [
        {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '20',
                    fontWeight: 'bold',
                    formatter: '{d}%\n{b}'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                { value: 0, name: '应收款' },
                { value: 0, name: '已收款' },
                { value: 0, name: '待收款' },
            ]
        }
    ]
}

// 概况tabs - 收款情况 - 详情
export const receiptDetailOptions = [
    {
        label: "定金",
        name: "a1",
        value: 0,
    },
    {
        label: "电费",
        name: "a2",
        value: 0,
    },
    {
        label: "水费",
        name: "a3",
        value: 0,
    },
    {
        label: "租金",
        name: "a4",
        value: 0,
    },
    {
        label: "押金",
        name: "a5",
        value: 0,
    },
    {
        label: "服务费",
        name: "a6",
        value: 0,
    }
]

// 概况tabs - 小区动态
// 陈守亮暂时修改甲方数据
// 张晓瑜新增首页-小区动态-小区名称字段增加跳转
export function dynamicTableColumn(_this){
    return [
        {
            prop: "name",
            label: "小区名称",
            width: 180,
            className: 'column-tree',
            render: (h, {row}) => {
                const {name,children,code} = row;
                if(children){
                    return h("el-link", {
                        props: {type: "primary", underline: false},
                        on: {
                            click: () => {
                                _this.$router.push({name: "house-resource", params: {data: {code}}});
                            },
                        },
                        style: {}
                    }, name);
                }else if(name ) {
                    return h("el-link", {
                        props: {type: "primary", underline: false},
                        on: {
                            click: () => {
                                _this.$router.push({name:"house-resource", params: {datas: {name}}});
                            },
                        },
                        style: {}
                    }, name);
                }
            },
        },

        // 2024-4-1-富航-陈守亮-小区动态配合新需求进行修改表格数据
        {
            prop: "sumCount",
            // label: "总房数",
            label: "房数",
            // width: 80,
            // render: (h, {row}) => {
            //    //  陈守亮修改甲方暂时要求
            //    if(row.name === '拆迁安置房') return '3135'
            //     if(row.name != '拆迁安置房') return row.sumCount
            // },
        },

        {
            prop: "totalOutSideArea",
            // label: "总建筑面积(㎡)",
            label: "总面积",
            // width: 120,
            // render: (h, {row}) => {
            //     if(row.name === '富航青年公寓') return '7502.08'
            //     if(row.name === '回购经适房') return '60233.38'
            //     if(row.name === '拆迁安置房') return '271445.30'
            //     if(row.name === '五象四号点') return row.totalOutSideArea
            // },
        },
        // {
        //     // prop: "sumCount",
        //     prop: "sonSumCount",
        //     // label: "总房数",
        //     label: "总房数(间)",
        //     width: 80,
        // },
        {
            prop: "rent",
            // label: "已租房数"
            label: "有效合同房数",
            // width: 100,

            // render: (h, {row}) => {
            //     if(row.name === '富航青年公寓') return row.rent
            //     if(row.name === '回购经适房') return '590'
            //     if(row.name === '拆迁安置房') return '3005'
            //     if(row.name === '五象四号点') return row.rent
            // },
        },
        // {
        //     prop: "sonTotalOutSideArea",
        //     // label: "总建筑面积(㎡)",
        //     label: "总建筑面积(间)(㎡)",
        //     width: 120,
        //
        // },
        {
            prop: "rentOutSideArea",
            // label: "总建筑面积(㎡)",
            label: "有效合同面积(㎡)",
            // width: 120,

        },
        // 新增1
        // {
        //     prop: "rent",
        //     // label: "已租房数"
        //     label: "已租房数",
        //     width: 100,
        //
        //     // render: (h, {row}) => {
        //     //     if(row.name === '富航青年公寓') return row.rent
        //     //     if(row.name === '回购经适房') return '590'
        //     //     if(row.name === '拆迁安置房') return '3005'
        //     //     if(row.name === '五象四号点') return row.rent
        //     // },
        // },
        // {
        //     prop: "renOutSideArea",
        //     // label: "已租房源建筑面积(㎡)",
        //     label: "已租房源套建筑面积(㎡)",
        //     width: 150,
        //     // render: (h, {row}) => {
        //     //     if(row.name === '富航青年公寓') return row.renOutSideArea
        //     //     if(row.name === '回购经适房') return '50762.55'
        //     //     if(row.name === '拆迁安置房') return '253118.78'
        //     //     if(row.name === '五象四号点') return row.renOutSideArea
        //     // },
        // },
        // 新增2
        // {
        //     prop: "sonRenOutSideArea",
        //     // label: "已租房源建筑面积(㎡)",
        //     label: "已租房源间建筑面积(㎡)",
        //     width: 150,
        // },
        // 新增3
        // {
        //     prop: "sonRent",
        //     label: "已租套间数",
        //     // width: 100,
        //
        // },
        {
            prop: "rentRate",
            label: "有效合同出租率",
            // width: 80,
            // render: (h, {row}) => {
            //     if(row.name === '富航青年公寓') return row.rentRate
            //     if(row.name === '回购经适房') return '87.15'
            //     if(row.name === '拆迁安置房') return '95.85'
            //     if(row.name === '五象四号点') return row.rentRate
            // },
        },
        // 新增4
        // {
        //     prop: "sonRenOutSideArea",
        //     label: "已租房源套间建筑面积(㎡)",
        //     width: 180,
        //
        // },
        {
            prop: "maintain",
            label: "报修量",
            // width: 80,
        },
        // {
        //     prop: "maintainover",
        //     label: "已维修量",
        //     width: 80,
        // },
        {
            prop: "complaintCount",
            label: "投诉",
            // width: 80,
        },
        {
            prop: "newcon",
            label: "新签约",
            // width: 80,
        },
        // 新增5
        // {
        //     prop: "sonRentRate",
        //     label: "套间总出租百分比",
        //     width: 150,
        //     // render: (h, {row}) => {
        //     //     if(row.sonRentRate === 22.73) return '8848'
        //     //     if(row.sonRentRate != 22.73) return row.sonRentRate
        //     // },
        // },
        // {
        //     prop: "rentRate",
        //     label: "出租率(%)",
        //     // width: 80,
        //     // render: (h, {row}) => {
        //     //     if(row.name === '富航青年公寓') return row.rentRate
        //     //     if(row.name === '回购经适房') return '87.15'
        //     //     if(row.name === '拆迁安置房') return '95.85'
        //     //     if(row.name === '五象四号点') return row.rentRate
        //     // },
        // },
    ];
}
// 陈守亮-2024-4-1-富航首页换房发布暂时切换老表格
// export function dynamicTableColumn(_this){
//     return [
//         {
//             prop: "name",
//             label: "小区名称",
//             width: 180,
//             className: 'column-tree',
//             render: (h, {row}) => {
//                 const {name,children,code} = row;
//                 if(children){
//                     return h("el-link", {
//                         props: {type: "primary", underline: false},
//                         on: {
//                             click: () => {
//                                 _this.$router.push({name: "house-resource", params: {data: {code}}});
//                             },
//                         },
//                         style: {}
//                     }, name);
//                 }else if(name ) {
//                     return h("el-link", {
//                         props: {type: "primary", underline: false},
//                         on: {
//                             click: () => {
//                                 _this.$router.push({name:"house-resource", params: {datas: {name}}});
//                             },
//                         },
//                         style: {}
//                     }, name);
//                 }
//             },
//         },
//         {
//             prop: "sumCount",
//             // label: "总房数",
//             label: "总房数(套)",
//             width: 80,
//             // render: (h, {row}) => {
//             //    //  陈守亮修改甲方暂时要求
//             //    if(row.name === '拆迁安置房') return '3135'
//             //     if(row.name != '拆迁安置房') return row.sumCount
//             // },
//         },
//         {
//             prop: "totalOutSideArea",
//             // label: "总建筑面积(㎡)",
//             label: "总建筑面积(套)(㎡)",
//             width: 120,
//             // render: (h, {row}) => {
//             //     if(row.name === '富航青年公寓') return '7502.08'
//             //     if(row.name === '回购经适房') return '60233.38'
//             //     if(row.name === '拆迁安置房') return '271445.30'
//             //     if(row.name === '五象四号点') return row.totalOutSideArea
//             // },
//         },
//         {
//             // prop: "sumCount",
//             prop: "sonSumCount",
//             // label: "总房数",
//             label: "总房数(间)",
//             width: 80,
//         },
//         {
//             prop: "sonTotalOutSideArea",
//             // label: "总建筑面积(㎡)",
//             label: "总建筑面积(间)(㎡)",
//             width: 120,
//
//         },
//         // 新增1
//         {
//             prop: "rent",
//             // label: "已租房数"
//             label: "已租套房数",
//             width: 100,
//
//             // render: (h, {row}) => {
//             //     if(row.name === '富航青年公寓') return row.rent
//             //     if(row.name === '回购经适房') return '590'
//             //     if(row.name === '拆迁安置房') return '3005'
//             //     if(row.name === '五象四号点') return row.rent
//             // },
//         },
//         {
//             prop: "renOutSideArea",
//             // label: "已租房源建筑面积(㎡)",
//             label: "已租房源套建筑面积(㎡)",
//             // render: (h, {row}) => {
//             //     if(row.name === '富航青年公寓') return row.renOutSideArea
//             //     if(row.name === '回购经适房') return '50762.55'
//             //     if(row.name === '拆迁安置房') return '253118.78'
//             //     if(row.name === '五象四号点') return row.renOutSideArea
//             // },
//         },
//         // 新增2
//         // {
//         //     prop: "sonRenOutSideArea",
//         //     // label: "已租房源建筑面积(㎡)",
//         //     label: "已租房源间建筑面积(㎡)",
//         //     width: 150,
//         // },
//         // 新增3
//         {
//             prop: "sonRent",
//             label: "已租套间数",
//             width: 100,
//
//         },
//         // 新增4
//         {
//             prop: "sonRenOutSideArea",
//             label: "已租房源套间建筑面积(㎡)",
//
//         },
//         {
//             prop: "maintain",
//             label: "报修量",
//             width: 80,
//         },
//         {
//             prop: "maintainover",
//             label: "已维修量",
//             width: 80,
//         },
//         {
//             prop: "complaintCount",
//             label: "投诉量",
//             width: 80,
//         },
//         {
//             prop: "newcon",
//             label: "新签约",
//             width: 80,
//         },
//         // 新增5
//         {
//             prop: "sonRentRate",
//             label: "套间总出租百分比",
//             // render: (h, {row}) => {
//             //     if(row.sonRentRate === 22.73) return '8848'
//             //     if(row.sonRentRate != 22.73) return row.sonRentRate
//             // },
//         },
//         {
//             prop: "rentRate",
//             label: "出租率(%)",
//             width: 80,
//             // render: (h, {row}) => {
//             //     if(row.name === '富航青年公寓') return row.rentRate
//             //     if(row.name === '回购经适房') return '87.15'
//             //     if(row.name === '拆迁安置房') return '95.85'
//             //     if(row.name === '五象四号点') return row.rentRate
//             // },
//         },
//     ];
// }


// 首页tabs 退房列表
export const tableCheckOutColumn = [
    {
        label: "序号",
        type: "index",
        width: 180
    },
    {
        prop: "timestamp",
        label: "申请时间",
        width: 200,
        render: (h, {row}) => {
            const timeStamp = !row.timestamp ? "" : timeFormat(new Date(row.timestamp), 'yyyy-MM-dd HH:mm:ss');
            return h("span", timeStamp);
        }
    },
    {
        prop: "title",
        label: "内容",
        showOverflowTooltip: true
  },

];

// 首页tabs 投诉列表
export const tableComplaintColumn = [
    {
        prop: "feedSn",
        label: "投诉编号",
        width: 150,
    },
    {
        prop: "content",
        label: "投诉内容",
        showOverflowTooltip: true,
    },
    {
        prop: "feedType",
        label: "投诉类型",
        width: 100,
    },
    {
        prop: "leasorName",
        label: "投诉人",
        width: 100,
    },

    {
      prop: "phone",
      label: "联系方式",
      width: 100,
    },
  {
    prop: "address",
    label: "租房地址",
    width: 200,
  },
  {
  prop: "roomType",
  label: "住房类型",
  width: 100,
  },
    {
        prop: "",
        label: "投诉时间",
        render: (h, { row }) => {
            return timeFormat(new Date(row.feedDate),"yyyy-MM-dd HH:mm:ss");
        },
        width: 150,
    },
    {
      prop: "state",
      label: "跟进状态",
      // 2023-10-9-17-32-陈守亮-修改注释状态，展示只展示一种状态
      render: (h, { row }) => {
          if(row.state ==='未处理'){
              return row.state
          }else {
              return '已处理'
          }
      },
      width: 100,
  },
    {
        prop: "feedLeasor",
        label: "处理人",
        width: 100,
    },
    {
        prop: "feedContent",
        label: "处理说明",
        showOverflowTooltip: true,
    },
    {
        prop: "feedTime",
        label: "处理时间",
        showOverflowTooltip: true,
        render: (h, { row }) => {
            const {feedTime} = row;
            return feedTime ? timeFormat(new Date(feedTime),"yyyy-MM-dd HH:mm:ss") : "";
        },
        width: 150,
    },
    // {
    //     prop: "authFeedContent",
    //     label: "驳回说明",
    //     showOverflowTooltip: true,
    // },
];

// 首页tabs 维修列表
export const tableRepairColumn = [
    {
        prop: "repairSn",
        label: "报修编号",
    },
    {
        prop: "repairContent",
        label: "维修备注"
    },
    {
        prop: "repairTypeName",
        label: "维修项目",
    },
    {
        prop: "repairPeopleName",
        label: "联系人",
        width: 80,
    },
    {
        prop: "repairPeoplePhone",
        label: "联系方式",
    },
    {
        prop: "createTime",
        label: "报修时间",
        width: 100,
        render: (h, { row }) => {
            const {createTime} = row;
            return createTime ? timeFormat(new Date(createTime)) : "";
        }
    },
    // 2023-10-12 16:52:16 王江毅修改，没有字段无法发布特此注释 1、增加“区域”，区域参考财务管理界面
    {
        prop: "district",
        label: "区域",
        width: 100,
    },
    {
        prop: "repairAddress",
        label: "房间"
    },
    {
        prop: "maintainMan",
        label: "维修人",
        width: 80,
    },
    {
        prop: "commentdate",
        label: "维修时间",
        width: 100,
        render: (h, { row }) => {
            const {commentdate} = row;
            return commentdate ? timeFormat(new Date(commentdate)) : "";
        }
    },
    {
        prop: "statusName",
        label: "跟进状态",
        width: 100,
    },
    {
        prop: "confirmor",
        label: "确认人",
        width: 80,
    },
    // {
    //     prop: "",
    //     label: "用户评价"
    // },
    {
        prop: "auditComment",
        label: "备注"
    },
    {
        prop: "chargebackCause",
        label: "退单原因"
    },
];

export function renewalTableColumn(_this) {
    return [
        {
            type: "index",
            label: "序号",
            width: 60,
        },
        {
            prop: "createdTime",
            label: "申请时间",
            render: (h, { row }) => {
                return timeFormat(new Date(row.createdTime), "yyyy-MM-dd HH:mm:ss");
            }
        },
        {
            prop: "leasor.name",
            label: "申请人",
        },
        {
            prop: "apartment.name",
            label: "房间号",
            width: 200,
        },
        {
            prop: "leasor.tel1",
            label: "租户手机号",
        },
        // {
        //     prop: "auditStatus",
        //     label: "状态",
        // },
        {
            prop: "disposeUserName",
            label: "审批人",
            showOverflowTooltip: true,
        },
        {
            prop: "disposeDate",
            label: "审批时间",
            render: (h, { row }) => {
                return row.disposeDate?timeFormat(new Date(row.disposeDate), "yyyy-MM-dd HH:mm:ss"):'';
            },
            showOverflowTooltip: true,
        },
        {
            prop: "comment",
            label: "备注",
            showOverflowTooltip: true
        },
    ];
}

//逾期租户名单列表
export function slippageTableColumn(that) {
    return [

        {
            type: "selection",
            width: 60,
        },
        {
            prop: "district",
            label: "区域",
            width: 100,
            // render: (h, {row}) => {
            //     const {leasorName} = row;
            //     return h("el-link", {
            //         props: {type: "primary", underline: false},
            //         on: {
            //             click() {
            //                 that.$router.push({name: "tenant-management", params: {data: {leasorName}}});
            //             }
            //         }
            //     }, leasorName);
            // }
        },
        {
            prop: "apartmentCode ",
            width: 150,
            label: "住房类型",
            render: (h, {row}) => {
                const {apartmentCode} = row;
                return apartmentCode === '600221' ? '长租公寓' :  '保租性租赁住房'
            }
        },


        {
            prop: "apartmentName",
            label: "房源名称",
            width: 200,
            render: (h, {row}) => {
                const {apartmentName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName: apartmentName}}});
                        }
                    }
                }, apartmentName);
            }
        },
        {
            prop: "leasorType",
            label: "租户类型",
        },
        {
            prop: "leasorName",
            label: "租户名称",
            width: 100,
            render: (h, {row}) => {
                const {leasorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                        }
                    }
                }, leasorName);
            }
        },
        // {
        //     prop: "contractNum",
        //     label: "合同编号",
        //     width: 150,
        //     render: (h, {row}) => {
        //         const {contractNum} = row;
        //         return h("el-link", {
        //             props: {type: "primary", underline: false},
        //             on: {
        //                 click() {
        //                     that.$router.push({name: "individual-contract", params: {data: {contractNum}}});
        //                 }
        //             }
        //         }, contractNum);
        //     }
        // },
        {
            prop: "lateAmount",
            label: "逾期金额(元)",
        },
        {
            prop: "lateDate",
            label: "应收款日期",
            width: 100,
        },
        {
            prop: "lateDays",
            label: "逾期天数(天)",
            width: 100,
        },
        {
            prop: "collectionTime",
            label: "催收时间",
        },
    ];
}

export function comingDue(that) {
    return [
        {
            prop: "district",
            label: "区域",
            width: 150,
            // render: (h, {row}) => {
            //     const {leasorName} = row;
            //     return h("el-link", {
            //         props: {type: "primary", underline: false},
            //         on: {
            //             click() {
            //                 that.$router.push({name: "tenant-management", params: {data: {leasorName}}});
            //             }
            //         }
            //     }, leasorName);
            // }
        },
        {
            prop: "apartmentCode ",
            label: "住房类型",
            width: 150,
            render: (h, {row}) => {
                const {apartmentCode} = row;
                return apartmentCode === '600221' ? '长租公寓' :  '保租性租赁住房'
            }
        },
        {
            prop: "contractNum",
            label: "合同编号",
            width: 150,
        },



        {
            prop: "leasorName",
            label: "租户名称",
            width: 100,
            render: (h, {row}) => {
                const {leasorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                        }
                    }
                }, leasorName);
            }
        },

        {
            prop: "leasorPhone",
            label: "租户手机号",
            width: 120,
        },
        {
            prop: "apartmentName",
            label: "房源名称",
            width: 200,
            render: (h, {row}) => {
                const {apartmentName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName: apartmentName}}});
                        }
                    }
                }, apartmentName);
            }
        },
        {
            prop: "leasorType",
            label: "租户类型",
        },
        {
            prop: "endDate",
            label: "合同结束时间",
            width: 120,
        },
        {
            prop: "remainingDays",
            label: "剩余时间",
            render: (h, {row}) => {
                const {remainingDays} = row;
                return remainingDays + '天'
            }
        },
    ];
}